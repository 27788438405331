import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";

export default function Dip() {
  const [dip, setDip] = useState("");
  const [dipDecimal, setDipDecimal] = useState("");
  const [result, setResult] = useState(null);
  const [savedResults, setSavedResults] = useState([]);

  const dips = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210,
  ];
  const volumes = [
    12, 34, 62, 96, 134, 175, 221, 269, 321, 376, 433, 492, 554, 619, 685, 754,
    824, 897, 971, 1047, 1125, 1204, 1286, 1368, 1452, 1538, 1625, 1714, 1803,
    1895, 1987, 2081, 2176, 2272, 2369, 2467, 2566, 2667, 2768, 2871, 2974,
    3079, 3184, 3291, 3398, 3506, 3615, 3724, 3835, 3946, 4058, 4171, 4285,
    4399, 4514, 4630, 4746, 4863, 4981, 5099, 5218, 5337, 5457, 5578, 5699,
    5820, 5942, 6065, 6188, 6311, 6435, 6559, 6684, 6809, 6935, 7060, 7187,
    7313, 7440, 7567, 7695, 7823, 7951, 8079, 8208, 8337, 8466, 8595, 8725,
    8855, 8985, 9115, 9245, 9375, 9506, 9636, 9767, 9898, 10029, 10160, 10291,
    10422, 10553, 10684, 10815, 10947, 11078, 11209, 11340, 11471, 11602, 11733,
    11864, 11995, 12125, 12256, 12386, 12516, 12646, 12776, 12906, 13036, 13165,
    13294, 13423, 13662, 13680, 13808, 13936, 14063, 14191, 14318, 14444, 14570,
    14696, 14822, 14947, 15072, 15196, 15320, 15443, 15566, 15689, 15811, 15932,
    16053, 16174, 16294, 16413, 16532, 16650, 16768, 16885, 17001, 17117, 17232,
    17346, 17460, 17572, 17685, 17796, 14906, 18016, 18125, 18233, 18340, 18447,
    18552, 18657, 18760, 18863, 18964, 19064, 19164, 19262, 19359, 19455, 19550,
    19644, 19736, 19827, 19917, 20006, 20093, 20178, 20263, 20345, 20426, 20506,
    20584, 20660, 20734, 20807, 20877, 20946, 21012, 21077, 21139, 21198, 21255,
    21310, 21361, 21410, 21455, 21497, 21535, 21569, 21597, 21619, 21631,
  ];
  const dipDecimals = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210,
  ];
  const diffs = [
    1.2, 2.2, 2.8, 3.3, 3.8, 4.2, 4.5, 4.9, 5.2, 5.4, 5.7, 6, 6.2, 6.4, 6.6,
    6.9, 7.1, 7.2, 7.4, 7.6, 7.8, 7.9, 8.1, 8.3, 8.4, 8.6, 8.7, 8.8, 9, 9.1,
    9.2, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10, 10.1, 10.3, 10.3, 10.4, 10.5, 10.6,
    10.7, 10.8, 10.9, 11, 11.1, 11.1, 11.2, 11.3, 11.4, 11.4, 11.5, 11.6, 11.6,
    11.7, 11.8, 11.8, 11.9, 11.9, 12, 12, 12.1, 12.2, 12.3, 12.3, 12.3, 12.4,
    12.4, 12.5, 12.5, 12.6, 12.6, 12.6, 12.6, 12.7, 12.7, 12.7, 12.8, 12.8,
    12.8, 12.8, 12.9, 12.9, 12.9, 12.9, 13, 13, 13, 13, 13, 13, 13.1, 13.1,
    13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1,
    13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13, 13, 13, 13, 13, 13,
    12.9, 12.9, 12.9, 12.9, 12.8, 12.8, 12.8, 12.8, 12.7, 12.7, 12.7, 12.6,
    12.6, 12.6, 12.5, 12.5, 12.4, 12.4, 12.3, 12.3, 12.3, 12.2, 12.2, 12.1, 12,
    12, 11.9, 11.9, 11.8, 11.8, 11.7, 11.6, 11.6, 11.6, 11.4, 11.4, 11.3, 11.2,
    11.1, 11.1, 11, 10.9, 10.8, 10.7, 10.6, 10.5, 10.4, 10.3, 10.3, 10.1, 10,
    9.9, 9.8, 9.7, 9.6, 9.5, 9.4, 9.2, 9.1, 9, 8.8, 8.7, 8.6, 8.4, 8.3, 8.1,
    7.9, 7.8, 7.6, 7.4, 7.2, 7.1, 6.9, 6.6, 6.4, 6.2, 6, 5.7, 5.4, 5.2, 4.9,
    4.5, 4.2, 3.8, 3.3, 2.8, 2.2, 1.2,
  ];

  // Load saved results from localStorage on component mount
  useEffect(() => {
    const storedResults =
      JSON.parse(localStorage.getItem("savedResults")) || [];
    setSavedResults(storedResults);
  }, []);

  const calculateValue = () => {
    const dipValue = parseInt(dip, 0);
    const dipDecimalValue = parseFloat(dipDecimal);

    if (
      isNaN(dipValue) ||
      isNaN(dipDecimalValue) ||
      dipValue < 0 ||
      dipValue > 210 ||
      dipDecimalValue < 0 ||
      dipDecimalValue > 9
    ) {
      alert("Please enter valid DIP and DIP Decimal values.");
      return;
    }

    const dipIndex = dips.indexOf(dipValue);
    const dipDecimalIndex = dipDecimals.indexOf(dipDecimalValue);

    if (dipIndex === -1 || dipDecimalIndex === -1) {
      alert("DIP or DIP Decimal value not found.");
      return;
    }

    const volume = volumes[dipIndex];
    const diff = diffs[dipIndex];

    const firstResult = volume;
    const secondResult = dipDecimalValue * diff;

    const finalResult = firstResult + secondResult;
    const calculatedResult = finalResult.toFixed(0);

    setResult(calculatedResult);

    // Get current date and time
    const timestamp = new Date().toLocaleString();

    // Save result to localStorage
    const newResult = {
      dip: dipValue,
      dipDecimal: dipDecimalValue,
      result: calculatedResult,
      timestamp: timestamp,
    };

    const updatedResults = [...savedResults, newResult];
    setSavedResults(updatedResults);
    localStorage.setItem("savedResults", JSON.stringify(updatedResults));
  };

  const deleteResult = (indexToDelete) => {
    const updatedResults = savedResults.filter(
      (_, index) => index !== indexToDelete
    );
    setSavedResults(updatedResults);
    localStorage.setItem("savedResults", JSON.stringify(updatedResults));
  };

  return (
    <div className="dip-calculator">
      <div className="container">
        <div className="form">
          <span>DIP Calculator</span>
          <div>
            <div className="row">
              <label className="col-4 dip-calculator-label"> DIP</label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="00"
                  value={dip}
                  onChange={(e) => setDip(e.target.value)}
                />
              </div>
            </div>
            <br />

            <div className="row">
              <label className="col-4 dip-calculator-label">
                {" "}
                DIP Decimals
              </label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  value={dipDecimal}
                  placeholder="00"
                  onChange={(e) => setDipDecimal(e.target.value)}
                />
              </div>
            </div>
            <br />
            <button
              onClick={calculateValue}
              className="btn btn-primary bt-form"
            >
              Calculate
            </button>
          </div>
          {result !== null && <p>Calculated Value: {result}</p>}

          {savedResults.map((item, index) => (
            <p key={index}>
              Date: {item.timestamp}, DIP: {item.dip}.{item.dipDecimal} DIP:{" "}
              {item.result}
              <span onClick={() => deleteResult(index)}>
                <AiFillDelete />
              </span>
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
