import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AiFillDelete, AiOutlineUndo } from "react-icons/ai";

export default function Du() {
  const [entries, setEntries] = useState([]);
  const [initialValues, setInitialValues] = useState({
    MS: {
      opening: "",
      closing: "",
      testing: "5",
      subtotal: "",
      price: "107.46",
      total: "",
    },
    HSD: {
      opening: "",
      closing: "",
      testing: "5",
      subtotal: "",
      price: "92.77",
      total: "",
    },
    credit1: "",
    card: "",
    scan: "",
    cash: "",
    grandTotal: "",
  });

  // Load from localStorage when the component is mounted
  useEffect(() => {
    const storedEntries = JSON.parse(localStorage.getItem("entries")) || [];
    setEntries(storedEntries);

    if (storedEntries.length > 0) {
      const latestEntry = storedEntries[storedEntries.length - 1];
      setInitialValues({
        MS: {
          opening: latestEntry.MS_opening,
          closing: latestEntry.MS_closing,
          testing: latestEntry.MS_testing,
          subtotal: latestEntry.MS_subtotal,
          price: latestEntry.MS_price,
          total: latestEntry.MS_total,
        },
        HSD: {
          opening: latestEntry.HSD_opening,
          closing: latestEntry.HSD_closing,
          testing: latestEntry.HSD_testing,
          subtotal: latestEntry.HSD_subtotal,
          price: latestEntry.HSD_price,
          total: latestEntry.HSD_total,
        },
        credit1: latestEntry.credit1,
        card: latestEntry.card,
        scan: latestEntry.scan,
        cash: latestEntry.cash,
        grandTotal: latestEntry.grandTotal,
      });
    }
  }, []);

  const handleFormSubmit = (values, { resetForm }) => {
    const newEntry = { ...values, timestamp: new Date().toLocaleString() };
    const newEntries = [...entries, newEntry];

    // Save to local storage
    localStorage.setItem("entries", JSON.stringify(newEntries));

    setEntries(newEntries);
    resetForm();
  };
  // --------------------reuse code-------------------
  const handleReuse = (entry, setFieldValue) => {
    setFieldValue("MS.opening", entry.MS.closing);
    setFieldValue("MS.closing");
    setFieldValue("MS.testing");
    setFieldValue("MS.subtotal");
    setFieldValue("MS.price");
    setFieldValue("MS.total");

    setFieldValue("HSD.opening", entry.HSD.closing);
    setFieldValue("HSD.closing");
    setFieldValue("HSD.testing");
    setFieldValue("HSD.subtotal");
    setFieldValue("HSD.price");
    setFieldValue("HSD.total");

    setFieldValue("credit1");
    setFieldValue("card");
    setFieldValue("scan");
    setFieldValue("cash");
    setFieldValue("grandTotal");
  };

  // --------------------Delete code-------------------
  const handleDelete = (timestamp) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      // Filter out the entry by its timestamp
      const updatedEntries = entries.filter(
        (entry) => entry.timestamp !== timestamp
      );

      // Update the state
      setEntries(updatedEntries);

      // Update localStorage
      localStorage.setItem("entries", JSON.stringify(updatedEntries));
    }
  };

  // --------------------calculate code-------------------

  const calculateSubtotal = (opening, closing, testing) => {
    return closing - opening - testing;
  };

  const calculateTotal = (subtotal, price) => {
    return subtotal * price;
  };

  const calculateGrandTotal = (
    msTotal,
    hsdTotal,
    credit1,
    card,
    scan,
    cash
  ) => {
    return msTotal + hsdTotal - credit1 - card - scan - cash;
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ values, setFieldValue }) => (
          <div className="du-detils">
            <div className="container">
              <div className="form">
                <Form>
                  {["MS", "HSD"].map((product, index) => (
                    <div key={index} className="product-form">
                      <div className="row align-items-end">
                        <div className="col-md-1">
                          <p>{product}</p>
                        </div>

                        <div className="col-md-2 col-6">
                          {/* <label htmlFor={`${product}.opening`}>Opening</label> */}

                          <Field
                            type="number"
                            placeholder="Opening"
                            className="form-control"
                            name={`${product}.opening`}
                            onChange={(e) => {
                              const opening = parseFloat(e.target.value);
                              const closing = parseFloat(
                                values[product].closing || 0
                              );
                              const testing = parseFloat(
                                values[product].testing || 0
                              );
                              const subtotal = calculateSubtotal(
                                opening,
                                closing,
                                testing
                              );
                              const total = calculateTotal(
                                subtotal,
                                parseFloat(values[product].price || 0)
                              );
                              setFieldValue(
                                `${product}.opening`,
                                e.target.value
                              );
                              setFieldValue(`${product}.subtotal`, subtotal);
                              setFieldValue(`${product}.total`, total);
                              setFieldValue(
                                "grandTotal",
                                calculateGrandTotal(
                                  product === "MS"
                                    ? total
                                    : parseFloat(values.MS.total || 0),
                                  product === "HSD"
                                    ? total
                                    : parseFloat(values.HSD.total || 0),
                                  parseFloat(values.credit1 || 0),
                                  parseFloat(values.card || 0),
                                  parseFloat(values.scan || 0),
                                  parseFloat(values.cash || 0)
                                )
                              );
                            }}
                          />
                          <ErrorMessage
                            name={`${product}.opening`}
                            component="div"
                          />
                        </div>
                        <div className="col-md-2 col-6">
                          {/* <label htmlFor={`${product}.closing`}>Closing</label> */}
                          <Field
                            type="number"
                            className="form-control"
                            placeholder="Closing"
                            name={`${product}.closing`}
                            onChange={(e) => {
                              const closing = parseFloat(e.target.value);
                              const opening = parseFloat(
                                values[product].opening || 0
                              );
                              const testing = parseFloat(
                                values[product].testing || 0
                              );
                              const subtotal = calculateSubtotal(
                                opening,
                                closing,
                                testing
                              );
                              const total = calculateTotal(
                                subtotal,
                                parseFloat(values[product].price || 0)
                              );
                              setFieldValue(
                                `${product}.closing`,
                                e.target.value
                              );
                              setFieldValue(`${product}.subtotal`, subtotal);
                              setFieldValue(`${product}.total`, total);
                              setFieldValue(
                                "grandTotal",
                                calculateGrandTotal(
                                  product === "MS"
                                    ? total
                                    : parseFloat(values.MS.total || 0),
                                  product === "HSD"
                                    ? total
                                    : parseFloat(values.HSD.total || 0),
                                  parseFloat(values.credit1 || 0),
                                  parseFloat(values.card || 0),
                                  parseFloat(values.scan || 0),
                                  parseFloat(values.cash || 0)
                                )
                              );
                            }}
                          />
                          <ErrorMessage
                            name={`${product}.closing`}
                            component="div"
                          />
                        </div>
                        <div className="col-md-1 col-6">
                          {/* <label htmlFor={`${product}.testing`}>Testing</label> */}
                          <Field
                            type="number"
                            placeholder="Testing"
                            className="form-control"
                            name={`${product}.testing`}
                            onChange={(e) => {
                              const testing = parseFloat(e.target.value);
                              const opening = parseFloat(
                                values[product].opening || 0
                              );
                              const closing = parseFloat(
                                values[product].closing || 0
                              );
                              const subtotal = calculateSubtotal(
                                opening,
                                closing,
                                testing
                              );
                              const total = calculateTotal(
                                subtotal,
                                parseFloat(values[product].price || 0)
                              );
                              setFieldValue(
                                `${product}.testing`,
                                e.target.value
                              );
                              setFieldValue(`${product}.subtotal`, subtotal);
                              setFieldValue(`${product}.total`, total);
                              setFieldValue(
                                "grandTotal",
                                calculateGrandTotal(
                                  product === "MS"
                                    ? total
                                    : parseFloat(values.MS.total || 0),
                                  product === "HSD"
                                    ? total
                                    : parseFloat(values.HSD.total || 0),
                                  parseFloat(values.credit1 || 0),
                                  parseFloat(values.card || 0),
                                  parseFloat(values.scan || 0),
                                  parseFloat(values.cash || 0)
                                )
                              );
                            }}
                          />
                          <ErrorMessage
                            name={`${product}.testing`}
                            component="div"
                          />
                        </div>
                        <div className="col-md-2 col-6">
                          {/* <label htmlFor={`${product}.subtotal`}>Subtotal</label> */}
                          <Field
                            type="number"
                            placeholder="Subtotal"
                            className="form-control"
                            name={`${product}.subtotal`}
                            readOnly
                            value={values[product].subtotal}
                          />
                          <ErrorMessage
                            name={`${product}.subtotal`}
                            component="div"
                          />
                        </div>

                        <div className="col-md-2 col-6">
                          {/* <label htmlFor={`${product}.price`}>Rate</label> */}
                          <Field
                            type="number"
                            className="form-control"
                            placeholder="Rate"
                            name={`${product}.price`}
                            onChange={(e) => {
                              const price = parseFloat(e.target.value);
                              const total = calculateTotal(
                                parseFloat(values[product].subtotal || 0),
                                price
                              );
                              setFieldValue(`${product}.price`, e.target.value);
                              setFieldValue(`${product}.total`, total);
                              setFieldValue(
                                "grandTotal",
                                calculateGrandTotal(
                                  product === "MS"
                                    ? total
                                    : parseFloat(values.MS.total || 0),
                                  product === "HSD"
                                    ? total
                                    : parseFloat(values.HSD.total || 0),
                                  parseFloat(values.credit1 || 0),
                                  parseFloat(values.card || 0),
                                  parseFloat(values.scan || 0),
                                  parseFloat(values.cash || 0)
                                )
                              );
                            }}
                          />
                          <ErrorMessage
                            name={`${product}.price`}
                            component="div"
                          />
                        </div>
                        <div className="col-md-2 col-6">
                          {/* <label htmlFor={`${product}.total`}>Total</label> */}
                          <Field
                            type="number"
                            placeholder="Total"
                            className="form-control"
                            name={`${product}.total`}
                            readOnly
                            value={values[product].total}
                          />
                          <ErrorMessage
                            name={`${product}.total`}
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="product-form">
                    <div className="row">
                      <div className="col-md-3 col-6">
                        {/* <label htmlFor="credit1">Credit</label> */}
                        <Field
                          type="number"
                          placeholder="Credit"
                          className="form-control"
                          name="credit1"
                          onChange={(e) => {
                            setFieldValue("credit1", e.target.value);
                            setFieldValue(
                              "grandTotal",
                              calculateGrandTotal(
                                parseFloat(values.MS.total || 0),
                                parseFloat(values.HSD.total || 0),
                                parseFloat(e.target.value),
                                parseFloat(values.card || 0),
                                parseFloat(values.scan || 0),
                                parseFloat(values.cash || 0)
                              )
                            );
                          }}
                        />
                        <ErrorMessage name="credit1" component="div" />
                      </div>
                      <div className="col-md-3 col-6">
                        {/* <label htmlFor="card">Card</label> */}
                        <Field
                          type="number"
                          className="form-control"
                          placeholder="Card"
                          name="card"
                          onChange={(e) => {
                            setFieldValue("card", e.target.value);
                            setFieldValue(
                              "grandTotal",
                              calculateGrandTotal(
                                parseFloat(values.MS.total || 0),
                                parseFloat(values.HSD.total || 0),
                                parseFloat(values.credit1 || 0),
                                parseFloat(e.target.value),
                                parseFloat(values.scan || 0),
                                parseFloat(values.cash || 0)
                              )
                            );
                          }}
                        />
                        <ErrorMessage name="card" component="div" />
                      </div>
                      <div className="col-md-3 col-6">
                        {/* <label htmlFor="scan">Scan</label> */}
                        <Field
                          type="number"
                          className="form-control"
                          placeholder="Scan"
                          name="scan"
                          onChange={(e) => {
                            setFieldValue("scan", e.target.value);
                            setFieldValue(
                              "grandTotal",
                              calculateGrandTotal(
                                parseFloat(values.MS.total || 0),
                                parseFloat(values.HSD.total || 0),
                                parseFloat(values.credit1 || 0),
                                parseFloat(values.card || 0),
                                parseFloat(e.target.value),
                                parseFloat(values.cash || 0)
                              )
                            );
                          }}
                        />
                        <ErrorMessage name="scan" component="div" />
                      </div>
                      <div className="col-md-3 col-6">
                        {/* <label htmlFor="cash">Cash</label> */}
                        <Field
                          type="number"
                          className="form-control"
                          placeholder="Cash"
                          name="cash"
                          onChange={(e) => {
                            setFieldValue("cash", e.target.value);
                            setFieldValue(
                              "grandTotal",
                              calculateGrandTotal(
                                parseFloat(values.MS.total || 0),
                                parseFloat(values.HSD.total || 0),
                                parseFloat(values.credit1 || 0),
                                parseFloat(values.card || 0),
                                parseFloat(values.scan || 0),
                                parseFloat(e.target.value)
                              )
                            );
                          }}
                        />
                        <ErrorMessage name="cash" component="div" />
                      </div>
                      <div className="col-md-3 col-6">
                        {/* <label htmlFor="grandTotal">Grand Total</label> */}
                        <Field
                          type="number"
                          className="form-control"
                          placeholder="Grand Total"
                          name="grandTotal"
                          readOnly
                          value={values.grandTotal}
                        />
                        <ErrorMessage name="grandTotal" component="div" />
                      </div>
                      <div className="col-md-3 col-6">
                        <button
                          type="submit"
                          className="btn btn-primary bt-form"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>

                <div className="show-detils">
                  <table className="table table-dark table-striped">
                    <thead>
                      <tr>
                        <th>Actions</th>
                        <th>Time</th>
                        <th>MS_opening</th>
                        <th>MS_closing</th>
                        <th>MS_testing</th>
                        <th>MS_subtotal</th>
                        <th>MS_price</th>
                        <th>MS_total</th>
                        <th>HSD_opening</th>
                        <th>HSD_closing</th>
                        <th>HSD_testing</th>
                        <th>HSD_subtotal</th>
                        <th>HSD_price</th>
                        <th>HSD_total</th>
                        <th>credit1</th>
                        <th>card</th>
                        <th>scan</th>
                        <th>cash</th>
                        <th>grandTotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entries.map((data, i) => (
                        <tr key={i}>
                          <td>
                            <button
                              onClick={() => handleReuse(data, setFieldValue)}
                              className="btn btn-secondary"
                            >
                              <AiOutlineUndo />
                            </button>
                            <button
                              onClick={() => handleDelete(data.timestamp)}
                              className="btn btn-danger ms-2"
                            >
                              <AiFillDelete />
                            </button>
                          </td>
                          <td>{data.timestamp}</td>
                          <td>{data.MS.opening}</td>
                          <td>{data.MS.closing}</td>
                          <td>{data.MS.testing}</td>
                          <td>{data.MS.subtotal}</td>
                          <td>{data.MS.price}</td>
                          <td>{data.MS.total}</td>
                          <td>{data.HSD.opening}</td>
                          <td>{data.HSD.closing}</td>
                          <td>{data.HSD.testing}</td>
                          <td>{data.HSD.subtotal}</td>
                          <td>{data.HSD.price}</td>
                          <td>{data.HSD.total}</td>
                          <td>{data.credit1}</td>
                          <td>{data.card}</td>
                          <td>{data.scan}</td>
                          <td>{data.cash}</td>
                          <td>{data.grandTotal}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
