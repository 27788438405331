import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import calculator from "../Images/calculator.png";

export default function MoneyCounter() {
  const [showMoneyCounter, setShowMoneyCounter] = useState(false);
  const [denominations, setDenominations] = useState({
    500: "",
    200: "",
    100: "",
    50: "",
    20: "",
    10: "",
    5: "",
    2: "",
    1: "",
  });

  const [savedData, setSavedData] = useState([]);

  // Load data from localStorage on component mount
  useEffect(() => {
    const savedDataFromStorage =
      JSON.parse(localStorage.getItem("savedDenominations")) || [];
    setSavedData(savedDataFromStorage);
  }, []);

  const handleOpenMoneyCounter = () => setShowMoneyCounter(true);
  const handleCloseMoneyCounter = () => setShowMoneyCounter(false);

  // Function to calculate the total
  const calculateTotal = (data) => {
    const total = Object.entries(data || denominations).reduce(
      (sum, [denom, count]) => {
        return sum + parseFloat(denom) * (parseInt(count) || 0);
      },
      0
    );
    return total === 0 ? "" : total.toFixed(2);
  };

  const handleDenominationChange = (e) => {
    const { name, value } = e.target;
    setDenominations((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Save data to localStorage and update state with the current date
  const handleSave = () => {
    const date = new Date().toLocaleString(); // Get the current date and time
    const newEntry = {
      ...denominations,
      date,
      total: calculateTotal(denominations),
    }; // Add date and total to the entry
    const newData = [newEntry, ...savedData]; // Add the new entry at the beginning
    setSavedData(newData);
    localStorage.setItem("savedDenominations", JSON.stringify(newData));
    // alert("Data saved successfully!");
  };

  // Clear a specific entry from saved data and update localStorage
  const handleDelete = (index) => {
    const newData = savedData.filter((_, i) => i !== index);
    setSavedData(newData);
    localStorage.setItem("savedDenominations", JSON.stringify(newData));
    alert("Data deleted successfully!");
  };

  // Define the order of denominations
  const orderedDenominations = [
    "500",
    "200",
    "100",
    "50",
    "20",
    "10",
    "5",
    "2",
    "1",
  ];

  return (
    <div className="money_counter">
      <span className="bt-calculeter">
        <img
          src={calculator}
          alt="calculator"
          variant="primary"
          onClick={handleOpenMoneyCounter}
        ></img>
      </span>

      <Modal show={showMoneyCounter} onHide={handleCloseMoneyCounter}>
        <Modal.Header closeButton>
          <Modal.Title>Money Counter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row top-aj">
            {orderedDenominations.map((denom, index) => (
              <div key={index} className="form-group col-12">
                <div className="input-group mb-2">
                  <span className="input-group-text" id="basic-addon1">
                    {denom} ×
                  </span>
                  <input
                    type="number"
                    name={denom}
                    value={denominations[denom]}
                    onChange={handleDenominationChange}
                    placeholder="0"
                    className="form-control"
                  />
                  <span className="input-group-text">
                    ={" "}
                    {(
                      parseFloat(denom) * (parseInt(denominations[denom]) || 0)
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
            ))}
            <h4>Total: {calculateTotal()}</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMoneyCounter}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
        {/* Display saved data in a table */}
        {savedData.length > 0 && (
          <div className="saved-data-table mt-4">
            <span>Saved Data</span>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Total</th>
                  {orderedDenominations.map((denom) => (
                    <th key={denom}>{denom}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {savedData.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.date}</td> {/* Display the saved date */}
                    <td>{data.total}</td> {/* Display the total */}
                    {orderedDenominations.map((denom) => (
                      <td key={denom}>{data[denom] || 0}</td>
                    ))}
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(index)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Modal>
    </div>
  );
}
