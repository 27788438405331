import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Du from "./Component/Du";
import Header from "./Component/Header";
import Density from "./Component/Density";
import Dip from "./Component/Dip";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" Component={Du} />
          <Route exact path="/Density" Component={Density} />
          <Route exact path="/Dip" Component={Dip} />
        </Routes>
        <Header />
      </BrowserRouter>
    </div>
  );
}

export default App;
